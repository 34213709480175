/* Each map can be put into a div with specified CSS */
/* .map {
    position: relative;
    top: 200px;
} */
body,
html {
  font-family: "Nunito", sans-serif;
  background-color: transparent;
  background-image: none;
}

.map {
  background-color: #f3f4f4;
  background-image: none;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0px;
}

.marker-button {
  background: none;
  border: none;
}

.marker-button:hover {
  background-color: transparent;
  background: none;
}

.card-style .mapboxgl-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0%;
  padding-left: 0%;
  padding-right: 0%;
  border-radius: 10px;
  font-family: "Nunito", sans-serif;
  background-image: linear-gradient(170deg, #092a2f, rgb(0, 0, 0, 0.75));
  background-color: transparent;
}

.card-style .mapboxgl-popup-close-button {
  font-size: 30pt;
  padding-right: 5%;
  padding-left: 5%;
  background-color: rgba(5, 25, 28, 0.5);
  color: #f3f4f4;
  border-bottom-left-radius: 25px;
}

.card-style .mapboxgl-popup-close-button:hover {
  background-color: rgba(5, 25, 28, 0.75);
}

.h6 .mapboxgl-popup-content {
  color: blue;
}

.popupText {
  font-family: "Nunito", sans-serif;
}

.image-style {
  height: 400px;
  width: 400px;
  border-radius: 0px;
  padding-left: 0%;
  padding-right: 0%;
  font-family: "Nunito", sans-serif;
}

.button1 {
  border: none;
  left: 2px;
}

.button2 {
  border: none;
  left: 6%;
}

.pinImage {
  border-radius: 50%;
  cursor: pointer;
  border-style: solid;
  border-color: #f3f4f4;
  border-width: 3px;
  font-family: "Nunito", sans-serif;
  width: 100px;
  height: 100px;
}

.pinImage:hover {
  border-style: solid;
  border-color: #092a2f;
  border-width: 8px;
}

.MuiButtonBase-root {
  font-size: 12pt;
}

.popupDescription {
  font-family: "Nunito", sans-serif;
}

.typography {
  font-family: "Nunito", sans-serif;
}

.map-modal-tags-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.map-modal-tag {
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  background-color: #7c3224;
  color: #f3f4f4;
  font-size: 6pt;
  width: auto;
}

/*--------------------------------------------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 648px) {
  .map {
    position: absolute;
    left: 0px;
  }

  .pinImage {
    border-radius: 50%;
    cursor: pointer;
    border-style: solid;
    border-color: #f3f4f4;
    border-width: 3px;
    font-family: "Nunito", sans-serif;
    width: 60px;
    height: 60px;
  }
}
