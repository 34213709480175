
body, html {
    background-image: linear-gradient(170deg, #092A2F, rgb(0, 0, 0, .75), #7C3224);
    background-color: #092A2F;    
    font-family: 'Nunito', sans-serif;
    color: #F3F4F4;
}

.yesNoButton {
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: rgba(255, 255, 255, .25);
    color: #F3F4F4;
    font-size: 12pt;
    font-weight: 300;
    cursor: pointer;
    width: 5vw;
    height: 5vh;
}

.yesNoButton:hover {
    background-color: rgba(255, 255, 255, .5);
    cursor: pointer;

}




.uploadContainer {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    width: auto;
    scroll-behavior: smooth;
}


.headerUpload {
    width: auto;
    margin: 2vw;

}

.headline {
    font-size: 40pt;
    font-weight: bold;
}

.underline {
	width: 5vw;
	height: 5px;
	background-color: #7C3224;
	border: none;
	border-radius: 50px;
    margin-left: 0%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.subHeadline {
    font-size: 25pt;
    font-weight: 300;
    text-align: left;
    width: 40vw;
    height: 5vh;
}


.buttonWrapper {
    display: flex;
    flex-direction: row;

}

.checkbox {
    margin: 0px;
    padding: 10px;
    width: 50px;
}


.mapConsentStatement {
    font-size: 14pt;
    padding-left: 1vw;
    color: rgba(255, 255, 255, 1);

}

input, textarea, select {
    display: flex;
    padding: 12px 20px;
    margin: 20px 0;
    border-radius: 25px;
    border: none;
    width: 250px;
    font-family: 'Noto Sans', sans-serif;
    font-size: 12pt;
}


.description {
    display: flex;
    padding: 12px 20px;
    margin: 20px 0;
    border-radius: 25px;
    border: none;
    width: 300px;
    height: 50px;
    font-family: 'Noto Sans', sans-serif;
}

.inputsForm {
    display: flex;
    flex-direction: column;
}


.submitButton {
    color: #F3F4F4;
    background-color: rgb(10, 41, 46, .15);
    border-color: #F3F4F4;
    border-style: solid;
    text-align: center;
    font-size: 16pt;
    justify-content: center;
    cursor: pointer;
    
}

.submitButton:hover {
    color: #F3F4F4;
    background-color: rgb(10, 41, 46, .30);
    border-color: #F3F4F4;

}
.chooseFile {
    border-radius: 25px;
    background-color: #F3F4F4;
    color: rgb(10, 41, 46);
}

.imagePreview {

    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;

}

.imagePreviewBox {
    width: 70vh;
    height: 70vh;
    background: url("../Images/placeholder-image.jpg")center no-repeat;
    background-size: cover;   
    border-radius: 15px; 
}

.submitContainer {
    display: inline-flex;
    justify-content: left;
    align-items: left;
    width: 100%;
    height: 100px;
  
}

.errorMessage {
    margin-top: 2vh;
    font-size: 10pt;
}

.loadingIcon {
    width: .5rem;
    margin-left: .5rem;
    text-align: center;
    justify-content: center;
}

.uploadContainerFull {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: auto;
    margin-top: 15vh;


}

/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
@media (max-width:640px) {
    .uploadContainerFull {
        flex-direction: column;
        align-items: center;
        margin-top: 10vh;
    }

    .uploadContainer {
        width: 100%;
        align-items: center;
    }

    
    .headline {
        font-size: 30pt;
        font-weight: bold;
        width: 100%;
    }
    
    .underline {
        width: .8rem;
        height: 5px;
        background-color: #7C3224;
        border: none;
        border-radius: 50px;
        margin-left: 0%;
        margin-top: 10px;
        margin-bottom: 13px;
    }
    
    .subHeadline {
        font-size: 13pt;
        font-weight: 300;
        text-align: left;
        width: 100vw;
    }
    
    .imagePreviewBox {
        margin-top: 15vh;
        width: 40vh;
        height: 40vh;  
    }

    .yesNoButton {
        width: 15vw;
        margin-top: 1vh;
    }

    .submitContainer {
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100px;
        margin-bottom: 5vh;
    }
}