body, html { 
    font-family: 'Nunito', sans-serif;
	overflow-x: hidden;
	background-color: #1D3B25;
}


*{
	margin: 0;
	padding: 0;
}
ul,li{
	list-style: none;
	cursor: pointer;
}
body,html{
	width: 100%;
}
html{
	font-size: 100px;
}
body{
	font-size: .16rem;
	
}
a{
	text-decoration: none;
}
.fl {
	float: left;
}
.fr{
	float: right;
	height: 5%;
	align-items: center;
	justify-content: center;
	text-align: center;
	display: flex;

}

.profileImages {
	width: 150px;
	margin: 2vw;
	border-radius: 25px;

}

.imagesContainerProfile {
	justify-content: center;
	align-items: center;
	text-align: center;
	display: flex;
	flex-direction: row;
	
}

.container{
	width: 100%;
	height: 100%;
}

.img{
	display: block;
	width: 100%;
	margin: 0 auto;
}
.content{
	
background-image: linear-gradient(170deg, rgb(9, 42, 47, .15), rgb(0, 0, 0, .15), #092A2F, rgb(0, 0, 0, .75), #7C3224);
padding-bottom: 1rem;
background-color: transparent;
}





.navButtonTitlesHome {
    font-size: 10pt;
    display: block;
    margin-top: 10px;
    font-family: 'Nunito', sans-serif;
	border-color: rgb(255, 255, 255);
}

.navButton {
	background-image: linear-gradient(170deg, rgba(9, 42, 47, 0.15), rgba(0, 0, 0, 0.15), #092A2F, rgba(0, 0, 0, 0.75), #7C3224);
	background-size: 200% 200%;
	color: #F1F3F3;
	border-radius: 100%;
	width: 1.8rem;
	height: 1.8rem;
	transition: all 0.3s ease-in-out;
	justify-content: center;
	align-items: center;
	text-align: center;
	display: flex;
	border: solid;
	border-width: 5px;
	padding: 10px;
	margin: 10px;

	
}



.navButton:hover {
	background-position: right center;
	color: #F1F3F3;
	cursor: pointer;
  }


.borders{
	height: 5px;
	background-color: rgb(46, 44, 33, .50);
	margin: .4rem 0;
}



.aboutTitle {
	font-size: 50pt;
	margin-left: 5vw;
}


.underlineHome {
	margin-left: 5vw;
	margin-bottom: 8vw;
	margin-top: 1vw;
	width: 5vw;
	height: 5px;
	background-color: rgb(124, 50, 36);
	border: none;
	border-radius: 50px;
}

.split {
    margin: 0;
    width: 100%;
	margin-top: 5%;
	margin-bottom: 5%;
}

.left {
    float: left;
    width: 50vw;

	
}

.leftText {
	text-align: center;
	font-size: 6vh;
	font-weight: 200;
	padding-right: 10%;
	padding-left: 10%;
}
.right {
    overflow: hidden;
    width: 40vw;
	text-align: left;
	font-size: 3vh;
}

.partition {
	width: 90%;
	height: 1vh;
	background-color: rgb(128, 128, 128, .25);
	justify-content: center;
	margin: auto;
	border-radius: 25px;
}

.hyperlink {
	color: #B68930;
}

.hyperlink:hover {
	color: rgb(182, 137, 48, .55);
}



.fa-cloud-arrow-up {

	font-size: .4rem;
}

.fa-image {
	font-size: .4rem;
}

.fa-map-location-dot {
	font-size: .4rem;
}

	


.formInput {
    width: 30vw;
    height: 30px;
    border-radius: 10px;
    border: none;

}


.formInputDescription {
    width: 30vw;
    height: 100px;
    border-radius: 10px;
    border: none;
}

.formSubmitButton {
    border-radius: 25px;
    background-color: rgb(124, 50, 36);
    color: #F3F4F4;
    width: 100px;
    height: 50px;
    border: solid;
    font-size: 12pt;
    margin-top: 10px;
	justify-content: center;
	align-items: center;
	align-items: center;

	
}
.formSubmitButton:hover {
    background-color: rgb(255, 255, 255, .25);
	cursor: pointer;
}
.formSubmitButton:active {
    background-color: rgb(255, 255, 255, .55);
	cursor: pointer;
}

.commentForm {
	justify-content: center;
	align-items: center;
	align-items: center;
	border-radius: 0;
	border: none;
	/* width: 32.5vw; */
	width: 100vw;
	margin-left: 5vw;
	margin-bottom: 10vw;
	margin-top: 3vw;
}

.commentFormTitle {
	font-size: 50pt;
	margin-left: 5vw;

}

.underlineHomeComment {
	margin-left: 5vw;
	margin-bottom: 1vw;
	margin-top: 1vw;
	width: 5vw;
	height: 5px;
	background-color: rgb(124, 50, 36);
	border: none;
	border-radius: 50px;
}

.formWrapper {
	margin-top: 5vw;
	width: auto;

}




/*--------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
@media (max-width:648px) {

	/*About text and underline*/
	.aboutTitle {
		font-size: 30pt;
		margin-left: 5vw;
	}

	.underlineHome {
		margin-left: 5vw;
		margin-bottom: 8vw;
		margin-top: 1vw;
		width: 14vw;
		height: 5px;
		background-color: rgb(124, 50, 36);
		border: none;
		border-radius: 50px;
	}

	.img {
		display: block;
		width: 100%;
	}



	.right {
		overflow: hidden;
		width: 90vw;
		text-align: left;
		font-size: 3vh;
		padding-left: 3vh;
		padding-right: 3vw;
		padding-top: 4vh;/*space between title and pargraph*/
	}

	.left {
		width: 95vw;
		padding-left: 3vh;
		padding-right: 3vw;
	}

	.leftText {
		text-align: left;
		font-size: 4vh;
		font-weight: 200;
		/*padding-right: 1%;*/
		padding-left: 0;
	}
	
	.commentFormTitle {
		font-size: 30pt;
		text-align:left;
	}

	.commentForm{
		padding:10px;
		padding-left: 10px;
		padding-top: 20px;
	}

	.underlineHomeComment {
		margin-left: 5vw;
		margin-bottom: 1vw;
		margin-top: 1vw;
		width: 14vw;
		height: 5px;
		background-color: rgb(124, 50, 36);
		border: none;
		border-radius: 50px;
	}

	.formSubmitButton{
		padding-left: 20px;
		width: auto;
		border-radius: 15px;
		position: relative;
		left:auto;
		font-size: 2vh;
	}

	.formInput {
		width: 75vw;
		height: 30px;
		border-radius: 10px;
		border: none;
	
	}
	
	.formInputDescription {
		width: 75vw;
		height: 100px;
		border-radius: 10px;
		border: none;
	}

	}
	

	.buttonsContainer {
		display: flex;
		flex-direction: row;	
		justify-content: space-around;
		gap: auto;	
		margin-top: 5vh;

	}

	@media screen and (max-width: 700px) {
		.buttonsContainer {
			display: flex;
			flex-direction: row;
			gap: auto;
			margin-top: 5vh;	
			
		}

		.navButton {
			margin: 10px;
			border-radius: 100%;
			width: 1rem;
			height: 1rem;
			padding: 0;
		}
		
	}


	.header-wrapper {
		position: sticky;
		top: 0;
	}

	.header {
		background-image: url('../Images/headerImage.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 100vh;
		width: 100vw;
				
	  }

	  @media (max-width: 1550px) {
		.header {
		  background-image: url('../Images/headerImage1.png');
		}
	  }

	  @media (max-width: 1150px) {
		.header {
		  background-image: url('../Images/headerImage2.png');
		}
	  }

	  @media (max-width: 767px) {
		.header {
		  background-image: url('../Images/headerImageMobile.png');
		}
		
		
	  }
	  
