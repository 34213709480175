.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3vh; /* Adjust the height to center vertically within the desired container */
}

.load-button {
  padding: 10px 20px; /* Adjust the padding as needed */
  margin-bottom: 5vh;
  background-color: #007bff; /* Change the background color */
  color: #fff; /* Change the text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
}

.load-button:hover {
  background-color: #0056b3; /* Change the background color on hover */
}
.gallery {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 2vw;
  }
  
  .gallery-item {
    width: calc(25% - 40px);
    margin-bottom: 20px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    margin-right: 1vw;
    left: 4%;
  }
  
  .gallery-item img:hover {
    opacity: 0.5;
  }
  
  .gallery-item img {
    object-fit: cover;
    width: 100%;
    height: 250px;
    border-radius: 5px;
    cursor: pointer;
  }
  

  .modal-description {
    color: #fff;
    font-size: 24pt;
    display: flex;
    flex-direction: column;
    transition: opacity 0.3s ease-in-out;
  }


  .modal-address {
   color: #F3F4F4;
    font-size: 10pt;
    display: flex;
    flex-direction: column;
    transition: opacity 0.3s ease-in-out;
    margin-top: 20px;

  }


  .modal-date-time {
   color: #F3F4F4;
    font-size: 8pt;
    display: flex;
    flex-direction: column;
    transition: opacity 0.3s ease-in-out;

  }
  
  .gallery-item-info h3,
  .gallery-item-info p {
    margin: 5px 0;
  }
  
  .gallery-item-info h3 {
    font-size: 24px;
  }
  
  .gallery-item-info p {
    font-size: 16px;
  }
  


  
  .modal-image-container img {
    max-width: 100%;
    max-height: 100vh;
    border-radius: 10px;
    cursor: zoom-in;
    background-color: #0B2023;
  }
  
  .modal-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #0B2023;
    overflow-x: hidden;

  }

  @media screen and (max-width: 768px) {
    .modal-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #0B2023;
      }
  }
    


  
  .modal-image-container {
    background-color: #0B2023;
  }
  
  .modal-image-container img {
    max-width: 100%;
    max-height: 75vh;
    object-fit: contain;
  }
  
  .modal-details-container {
    margin-bottom: 20px;
    text-align: center;
    padding: 50px;
    background-color: #0B2023;
  }

  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 32px;
    cursor: pointer;
  }
  
  .modal-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 32px;
    cursor: pointer;
    color: #F1F3F3;
    text-shadow: 1px 1px 2px #000;
  }
  
  .modal-arrow.left {
    left: 20px;
  }
  
  .modal-arrow.right {
    right: 20px;
  }
  
  @media screen and (max-width: 768px) {
    .gallery-item {
      width: calc(50% - 20px);
      margin-right: 2.5vw;
    }
  }
  
  @media screen and (max-width: 480px) {
    .gallery-item {
      width: calc(100% - 20px);
      margin-right: 2.5vw;
    }
  }
  

  .modal-button {
    padding: 10px;
    margin: auto;
    border-radius: 20px;
    border: 1px solid;
    margin-bottom: 20px;
    background-color: #0B2023;
    width: 100px;
    color: #F3F4F4;
  }

  .arrow {
    font-size: 12pt;
  }
  .modal-button:hover {
    background-color: #616E6F;
    cursor: pointer;
  }

  .modal-buttons-container {
    display: flex;
    flex-direction: row;
    margin-top: 4vh;
    align-items: center;
    justify-content: center;

  }



@media screen and (max-width: 768px) {
    .x {
        margin: 20px;
        color: #F1F3F3;
      }
}

@media screen and (max-width: 480px) {
    .x {
        margin: 20px;
        color: #F1F3F3;
      }
}

  .x {
    margin: 20px;
    color: #F1F3F3;
  }


  .contentBox{
		width: auto;
    margin-top: 100px;
	}


.container_h{
	overflow: hidden;
  padding: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;


}

.galleryTitle {
	font-size: 50pt;
 

}

.galleryTitleWrapper {
  display: flex;
  flex-direction: column;
}

.underlineGallery {
	margin-top: 1vw;
	width: .8rem;
	height: 5px;
	background-color: #7C3224;
	border: none;
	border-radius: 50px;

}

.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter-select {
  margin-right: 5%;
  display: flex;
  flex-direction: row;
  
}

.theme-select {
  margin-right: 5%;
}

.modal-tags-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.modal-tag {
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  background-color: #7C3224;
  color: #F3F4F4;
  font-size: 6pt;
  
}

@media screen and (max-width: 768px) {
  .filter {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Changed from align-self to align-items */
  }
  
  .filter-select {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Changed from justify-content to align-items */
  }

  .container_h {
    margin: 0;
    padding: 0;
    padding-left: 5vw;
  }

  .galleryTitle {
    font-size: 40pt;
  }

  .underlineGallery {
    margin-bottom: 2vw;
  }

  .modal-description {
    font-size: 16pt;
    width: 75vw;
    padding: 1vw;
  }

  .modal-details-container {
    text-align: center;
    padding: 0px;
    margin-bottom: 0;
    background-color: #0B2023;
  }

  .modal-tags-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .modal-address {
    color: #F3F4F4;
     font-size: 10pt;
     display: flex;
     flex-direction: column;
     transition: opacity 0.3s ease-in-out;
     margin-top: 5px;
     margin-bottom: 5px;
     padding-right: 10px;
    padding-left: 10px;
 
   }


  .modal-buttons-container {
    display: flex;
    flex-direction: row;
    margin-top: 1vh;
    align-items: center;
    justify-content: center;

  }
 
  
}
  

