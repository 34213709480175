body, html {
    font-family: 'Nunito', sans-serif;
    width: 100%;
}

.navbar {
    width: 10vw;
    height: auto;
    background-color: transparent;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
}


.nav-hamburger-container {
    margin: 2vw;
    float: left; 
}
    
.icons:hover {
    color: rgb(255, 255, 255, .50)
}

.icons:active {
    color: rgb(255, 255, 255, .20)
}
.icons {
    color: #F3F4F4;
    font-size: 3vw;
    border-color: #F3F4F4;
    cursor: pointer;
    
    
    
}

.icons-inner {
    color: #F3F4F4;
    font-size: 3vw;
    border-color: #F3F4F4;
    cursor: pointer;
    margin-top: 2vw;
    margin-left: 2vw;
    
}



.nav-button-titles {
    font-size: 15pt;
    padding-left: 2vw;
    font-family: 'Nunito', sans-serif;
    color: #F3F4F4;
    padding: 2vw;

    
}

.nav-button-titles:hover {
    color: #d4d4d4; 
    background-color: rgb(255, 255, 255, .20);
    cursor: pointer;
    border-radius: 10px; 
}
.nav-button-titles:active {
    color: #082429;  
}

.navbar-logo {
    width: 5rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.nav-options-panel {

    width: 20vw;
    height: 90vh;
    /* make blurry background */
    background-image: linear-gradient(170deg, #092A2F, rgb(0, 0, 0, .75));
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10vh;
    transition: 1.5s ease-in-out;




}


@media screen and (max-width: 1000px) {
    .icons {
        color: #F3F4F4;
        font-size: 10vw;
        border-color: #F3F4F4;
        cursor: pointer;
        
    }

    .icons-inner {
        color: #F3F4F4;
        font-size: 10vw;
        border-color: #F3F4F4;
        cursor: pointer;
        
    }

    .nav-options-panel {
        width: 50vw;
    }

    .navbar {
        width: 10vw;
        height: auto;
        background-color: transparent;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 999;
    }

}

@media screen and (max-width: 820px) {
    .navbar {
        position: absolute;
    }
}